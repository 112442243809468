<script>
import { Doughnut, mixins } from 'vue-chartjs'
const { reactiveProp } = mixins

export default {
  extends: Doughnut,
  mixins: [reactiveProp],
  props: ['options'],
  mounted () {
    this.renderChart(this.chartData, {
      maintainAspectRatio: false,
      responsive: true,
      elements: {
        point: {
          radius: 0
        }
      },
      legend: {
        display: false
      },
      ...this.options
    })
  }
}
</script>
