<template>
  <div class="main-content">
    <div class="header bg-white border-bottom">
      <div class="container-fluid">
        <div class="header-body border-0">
          <div class="row align-items-end">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title">Transactions</h1>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-12 col-xl-8">
          <div class="card">
            <div class="card-header">
              <div>
                <h4 class="card-header-title">Transactions (30 days)</h4>

                <small class="text-muted me-3">User transactions over the last 30 days</small>
              </div>
            </div>
            <div class="card-body">
              <line-chart v-if="last30DaysTransactions" class="chart linechart" :chart-data="last30DaysTransactions"
                :options="lineChartOptions"></line-chart>
              <div v-if="loadingLast30DaysTransactions" class="d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-xl-4">
          <div class="card">
            <div class="card-header">
              <div>
                <h4 class="card-header-title">Transaction Distribution</h4>

                <small class="text-muted me-3">Type of users transactions (Credits, Debits)</small>
              </div>
            </div>
            <div class="card-body">
              <template v-if="transactionsDistrubution">
                <doughnut-chart class="chart doughnutchart" :chart-data="transactionsDistrubution"
                  :options="doughnutChartOptions"></doughnut-chart>
                <div id="trafficChartLegend" class="chart-legend">
                  <div>
                    <span class="chart-legend-item"><span class="chart-legend-indicator"
                        style="background-color: #00e069"></span>Credits</span><span class="chart-legend-item"><span
                        class="chart-legend-indicator" style="background-color: #fc7171"></span>Debits</span>
                  </div>
                </div>
              </template>
              <div v-if="loadingLast30DaysTransactions" class="d-flex justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <div class="row align-items-center">
                <div class="col">
                  <v-datepicker v-model="filter.range" placholder="Filter by date" range></v-datepicker>
                </div>
                <div class="col-auto">
                  <div class="form-inline">
                    <label class="form-control-label">Filter by source:</label>
                    <select v-model="filter.source" class="
                        form-control
                        ml-2
                        form-control-select
                        custom-select
                      ">
                      <option value="all_transactions">All transactions</option>
                      <option value="main_balance">Main Balance</option>
                      <option value="credit_balance">
                        Credit (Company) Balance
                      </option>
                      <option value="instant_payment">Instant Payment</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <b-table striped hover selectable show-empty responsive :items="options" :fields="fields"
            :current-page="currentPage" :busy="fetchingTransactions" @row-clicked="viewTransaction">
            <template #table-busy>
              <div class="d-flex flex-column justify-content-center align-items-center">
                <div class="spinner-border" role="status"></div>
                <p class="text-center mt-2"><strong>Loading...</strong></p>
              </div>

            </template>

            <template #empty>
              <p class="text-center text-secondary">{{ search ? `No records found for search value: "${search}"` : 'No records available'}}</p>
            </template>

            <template #cell(transaction_date)="data">
              {{ data.item.created_at | date('hh:mm a MMM d, yyyy') }}
            </template>

            <template #cell(description)="data">
              {{ data.item.title ?? 'N/A' }}
            </template>

            <template #cell(user)="data">
              {{ data.item.user.fname + data.item.user.lname ?? 'N/A' }}
            </template>

            <template #cell(amount)="data">
              {{ data.item.amount ?? 'N/A' }}
            </template>

            <template #cell(source)="data">
              <div>
                <span :class="data.item.type === 'debit' ? 'text-danger' : 'text-success'
                ">₦{{ data.item.amount | money }}</span>
                {{ data.item.payment_source.split('_').join(' ')  ?? 'N/A' }}
              </div>
            </template>
          </b-table>

          <div class="card-footer" v-if="totalRecords">
            <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage"></b-pagination>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import LineChart from '@/components/charts/LineChart.vue'
import DoughnutChart from '@/components/charts/DoughnutChart.vue'
import { format, parseISO } from 'date-fns'
import { computed, watch, ref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router/composables'
import { axiosInstance as axios } from '@/plugins/axios'
import moment from 'moment'

const search = ref('')

const router = useRouter()
const loadingLast30DaysTransactions = ref(true)
const errorLoadingLast30DaysTransactions = ref(false)
const last30DaysTransactions = ref(null)
const transactionsDistrubution = ref(null)
const lineChartOptions = ref({
  legend: {
    display: true
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
        },
        offset: true
      }
    ],
    yAxes: [
      {
        gridLines: {
          borderDash: [8, 4],
          color: '#F6F6F7'
        },
        ticks: {
          // stepSize: 1000000000,
          beginAtZero: true,
          scaleBeginAtZero: true
        }
      }
    ]
  },
  tooltips: {
    callbacks: {
      label: (tooltipItem, data) => {
        const dataset = data.datasets[tooltipItem.datasetIndex]
        const total = dataset.data.reduce(function (
          previousValue,
          currentValue
        ) {
          return previousValue + currentValue
        })
        return `₦${this.$options.filters.money(total)}`
      }
    }
  }
})
const doughnutChartOptions = ref({
  legend: {
    display: false
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const dataset = data.datasets[tooltipItem.datasetIndex]
        const total = dataset.data.reduce(function (
          previousValue,
          currentValue
        ) {
          return previousValue + currentValue
        })
        const currentValue = dataset.data[tooltipItem.index]
        const percentage = Math.floor((currentValue / total) * 100 + 0.5)
        return percentage + '%'
      }
    }
  }
})

const dateRange = computed(() => {
  return filter.value.range.map((date) => moment(date).format('YYYY-MM-DD'))
})

const perPage = ref(10)
const currentPage = ref(1)

const fields = ref([
        {
          key: 'transaction_date',
          label: 'Transaction Date'
        },
        {
          key: 'description',
          label: 'Description'
        },
        {
          key: 'user',
          label: 'User'
        },
        {
          key: 'amount',
          label: 'Amount'
        },
        {
          key: 'source',
          label: 'Source'
        }
      ])

const transactions = ref([])
const fetchingTransactions = ref(true)
const columns = ref(['Transaction Date', 'Description', 'User', 'Amount', 'Source'])
const options = ref([])
const filter = ref({
  source: 'all_transactions',
  range: ref([])
})

const dateFilterValid = computed(() => {
  return (
    filter.value.range.length &&
    filter.value.range.filter((date) => !!date).length > 0
  )
})


const transactionsTable = ref(null)

watch(filter, () => {
  transactionsTable.value.refresh()
})

watch(() => dateFilterValid.value, (val) => {
  fetchTransactions()
})

watch(() => dateRange.value, (newValue, oldValue) => {
  if (newValue[0] !== newValue[1] || oldValue[0] !== oldValue[1]) {
    fetchTransactions()
      }
})

watch(() => currentPage.value, () => {
  fetchTransactions()
})



const fetchTransactionsChartData = () => {
  loadingLast30DaysTransactions.value = true
  errorLoadingLast30DaysTransactions.value = false
  axios
    .get('/v1/transactions/graph')
    .then((res) => {
      setTransactionsChartData(res.data)
    })
    .catch(() => {
      errorLoadingLast30DaysTransactions.value = true
    })
    .finally(() => (loadingLast30DaysTransactions.value = false))
}


const totalRecords = ref(0)

const fetchTransactions = async (params) =>
{
  const query = params ? params.query : ''
  const baseUrl = query ? '/v1/transactions/search' : '/v1/transactions'
  let url = `${baseUrl}?limit=${perPage.value}&page=${currentPage.value}&metadata=true&related=user`

  if (dateFilterValid.value) {
    url += `&from=${dateRange.value[0]}&to=${dateRange.value[1]}`
  }
  if (filter.value.source) {
    url += `&source=${filter.value.source === 'all_transactions' ? '' : filter.value.source
      }`
  }
  await axios({
    url,
    method: query ? 'POST' : 'GET',
    data: {
      search_term: query
    }
  }).then((res) => {
          options.value = res.data.data
          totalRecords.value = res.data?.metadata?.total
          return options.value
        })
        .finally(() => (fetchingTransactions.value = false))

}

onMounted(() => {
  fetchTransactions()
})

const setTransactionsChartData = (data) => {
  const credits = []
  const debits = []
  const mappedData = {}
  for (const datum of data) {
    const dayInMonth = format(parseISO(datum.created_at), 'd-MM')
    if (mappedData[dayInMonth] && mappedData[dayInMonth][datum.type]) {
      mappedData[dayInMonth][datum.type] =
        mappedData[dayInMonth][datum.type] + parseFloat(datum.amount)
    } else {
      mappedData[dayInMonth] = {
        ...mappedData[dayInMonth],
        [datum.type]: parseFloat(datum.amount)
      }
    }
  }
  Object.values(mappedData)?.forEach((datum) => {
    if (datum.credit) {
      credits.push(datum.credit)
    }
    if (datum.debit) {
      debits.push(datum.debit)
    }
  })
  last30DaysTransactions.value = {
    labels: Object.keys(mappedData),
    datasets: [
      {
        label: 'Wallet Funding',
        backgroundColor: 'transparent',
        borderColor: '#00e069',
        data: credits
      },
      {
        label: 'Wallet Spends',
        backgroundColor: 'transparent',
        borderColor: '#fc7171',
        data: debits
      }
    ]
  }
  transactionsDistrubution.value = {
    labels: ['Wallet Funding', 'Wallet Spending'],
    datasets: [
      {
        data: [
          credits.reduce((sum, credit) => sum + credit, 0),
          debits.reduce((sum, debit) => sum + debit, 0)
        ],
        backgroundColor: ['#00e069', '#fc7171']
      }
    ]
  }
}

const viewTransaction = (transaction) => {
  router.push({
    name: 'transactions.view',
    params: { transactionId: transaction.row.id }
  })
}


fetchTransactionsChartData()
</script>
